<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-[600]"
  ></div>
  <div class="fixed top-0 bottom-0 left-0 right-0 z-[610] pt-xl md:p-[4vh]">
    <ContainerBasic :container-type="EContainerType.CONTENT" classes="h-full">
      <div
        class="relative flex items-end justify-center h-full md:items-center"
      >
        <div
          class="relative w-full min-w-[345px] max-h-full rounded-t-alt-xl md:rounded-alt-lg h-fit md:flex md:flex-col md:justify-center"
          :class="[widthClass]"
        >
          <div
            class="h-full bg-white md:h-auto md:max-h-full rounded-t-alt-lg md:rounded-alt-xl"
          >
            <slot />
          </div>
          <button
            v-if="showCloseButton"
            type="button"
            class="absolute text-xl cursor-pointer text-border-dark top-md right-md"
            @click="dialogStore.closeDialog()"
          >
            <FaIcon icon-class="fas fa-xmark" />
          </button>
        </div>
      </div>
    </ContainerBasic>
  </div>
</template>
<script setup lang="ts">
import ContainerBasic from '@/components/components/container-basic.vue';
import { useDialogStore } from '~/stores/useDialogStore';
import { EContainerType } from '~~/src/@types/container-type';
import FaIcon from '@/components/fa-icon.vue';

defineProps({
  widthClass: {
    type: String,
    default: 'md:max-w-[510px]',
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
});

const dialogStore = useDialogStore();

onMounted(() => {
  usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});

watch(useRouter().currentRoute, () => {
  if (dialogStore.open) dialogStore.closeDialog();
});
</script>
